/* eslint-disable import/prefer-default-export */
import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { IconStarUnfilled, TypographyWrapper } from '../../components';
import {
  TypographyFormat,
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import css from './ListingPage.module.css';
import { Listing } from '../../types/sharetribe/listing';
import { convertMoneyToNumber } from '../../util/currency';
import { trackClickLoyaltyPointsSection } from '../../util/heap';

interface SectionListingLoyaltyPointsProps {
  loyaltyPointsConfig: any;
  listing: Listing;
  className?: string;
}

export const SectionListingLoyaltyPoints: FC<sectionlistingloyaltypointsprops> = (props) => {
  const { loyaltyPointsConfig, listing, className } = props;
  const { pointsPerDollar, pointsName, moreInfoText, moreInfoLink } = loyaltyPointsConfig;
  const { price, publicData } = listing?.attributes || {};

  const potentialLoyaltyPoints = Math.ceil(pointsPerDollar * convertMoneyToNumber(price));

  const linkInfoWithTracking = `${moreInfoLink}?utm_source=treet&utm_campaign=listing-page`;

  const handleLoyaltyPointsClick = () => {
    trackClickLoyaltyPointsSection(
      publicData?.domain,
      publicData?.shopName,
      potentialLoyaltyPoints
    );
    return window.open(linkInfoWithTracking, '_blank');
  };

  return (
    <box className="{classNames(className," css.sectionIso)}="" onClick="{handleLoyaltyPointsClick}">
      <box display="flex" flexDirection="row" width="100%">
        <box display="flex" alignItems="center" pr="{3}">
          <iconstarunfilled className="{css.sectionIcon}"></iconstarunfilled>
        </box>
        <box display="flex" flexDirection="column" mr="{2}">
          <typographywrapper variant="body2" weight="{TypographyWeight.Bold}">
            Earn {potentialLoyaltyPoints} {pointsName} on this purchase
          </typographywrapper>
          <typographywrapper variant="body2" format="{TypographyFormat.Underlined}">
            {moreInfoText}
          </typographywrapper>
        </box>
      </box>
    </box>
  );
};
</sectionlistingloyaltypointsprops>