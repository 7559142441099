import React, { FC, ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import { IconArrowRight, TypographyWrapper } from '../../../components';
import css from './SectionConsolidatedListings.module.css';

interface SectionConsolidatedListingsLinkRowProps {
  icon: ReactNode;
  description: string;
  onClick?: () => void;
}

const SectionConsolidatedListingsLinkRow: FC<sectionconsolidatedlistingslinkrowprops> = (
  props: SectionConsolidatedListingsLinkRowProps
) => {
  const { icon, description, onClick } = props;

  return (
    <box onClick="{onClick}" className="{classNames(" css.consolidatedTableRow,="" css.sectionConsolidatedListingsLinkRowWrapper="" )}="">
      <box display="flex" alignItems="center" justifyContent="space-between">
        <box display="flex" flexDirection="row" alignItems="center" width="100%">
          <box display="flex" alignItems="center" pr="{{" xs:="" 2,="" lg:="" '12px'="" }}="">
            {icon}
          </box>
          <typographywrapper variant="body2">{description}</typographywrapper>
        </box>
        <iconarrowright></iconarrowright>
      </box>
    </box>
  );
};

export default SectionConsolidatedListingsLinkRow;
</sectionconsolidatedlistingslinkrowprops>