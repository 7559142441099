/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Chip, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { string } from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Magnifier } from 'react-image-magnifiers';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { Builder } from '@builder.io/react';
import { useParams } from 'react-router-dom';
import { useShopConfig } from '../../hooks/shopConfig';
import ActionBarMaybe from './ActionBarMaybe';
import {
  defaultTreetStyles,
  FEATURED_LISTING_IMAGE_TYPE,
  FEATURED_LISTING_IMAGE_TYPE_TAB_NAMES,
} from '../../shopConfig/config';
import { Feature } from '../../util/featureFlags';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import {
  getLargeUploadcareImagePreviewUrl,
  getUploadcareImagePreviewUrl,
  ImageSource,
} from '../../util/uploadcare';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { getStockImagesForListing } from '../../util/listings/listingImages';
import { useIsMobile } from '../../hooks/useIsMobile';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { AvatarSmall } from '../../components/Avatar/Avatar';
import { IconUserFilled, InlineTextButton, TypographyWrapper } from '../../components';
import {
  TypographyFormat,
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import AppContext from '../../context/AppContext';
import { trackZoomPDPImage } from '../../util/heap';
import { types as sdkTypes } from '../../util/sdkLoader';
import ActionBarMaybeDesktop from './ActionBarMaybeDesktop';
import {
  createSlug,
  LISTING_PAGE_DRAFT_VARIANT,
  ListingPageParamType,
} from '../../util/urlHelpers';
import { useCurrentListing } from './hooks/useCurrentListing';
import css from './ListingPage.module.css';
import './ListingPageSwiper.css';
import { BackgroundBlurBox } from '../../components/BackgroundBlurBox/BackgroundBlurBox';

const { UUID } = sdkTypes;

const FALLBACK_IMAGE_RATIO_FOR_VERTICAL_IMAGES = 1;
const CAROUSEL_ELEMENT_IDENTIFIER = '.carousel .slider-wrapper';
const CAROUSEL_CLASSNAME_TARGET_TO_OBSERVE = 'slider-wrapper';
// For PDP Gallery V2
const CAROUSEL_ELEMENT_IDENTIFIER_PDP_GALLERY_V2 = '.carouselImagesWrapper';
const CAROUSEL_CLASSNAME_TARGET_TO_OBSERVE_PDP_GALLERY_V2 = 'swiper-wrapper';
const MEDIA_QUERY_LARGE_CAROUSEL_THUMBNAIL_WIDTH = '120px';
// Keep in sync with marketplace.css
const TOPBAR_HEIGHT_DESKTOP = 84;
const MIN_FULL_SCREEN_CAROUSEL_HEIGHT = 700;

const MEDIA_TYPE = {
  VIDEO: 'video',
  IMAGE: 'img',
};

const useImageChipStyles = makeStyles({
  root: {
    backgroundColor: (props) => props.backgroundColor,
    color: 'white',
    height: '42px',
    fontSize: '16px',
  },
});

const SWIPER_ARROW_STYLE = {
  color: defaultTreetStyles.gray60,
  backgroundColor: '#fff',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
};

const ImageChip = (props) => {
  const { backgroundColor, ...rest } = props;
  const classes = useImageChipStyles(props);
  return <chip classes="{classes}" {...rest}=""></chip>;
};

ImageChip.defaultProps = { backgroundColor: null };
ImageChip.propTypes = { backgroundColor: string };

const MagnifyImage = (props) => {
  const { imageSrc, largeImageSrc, imageAlt, listingId } = props;

  const { treetId } = useContext(AppContext);

  const handleZoomStart = () => trackZoomPDPImage(listingId, imageSrc, treetId);

  return (
    <magnifier Can't="" use="" CSS="" Modules="" to="" target="" Magnifier="" divs="" className="magnifier" imageSrc="{imageSrc}" largeImageSrc="{largeImageSrc}" imageAlt="{imageAlt}" dragToMove="{false}" onZoomStart="{handleZoomStart}"></magnifier>
  );
};

const StockOrSellerChip = (props) => {
  const { isStock, isVideo, user } = props;

  const { css: shopCss } = useShopConfig();
  const isPdpGalleryV2Enabled = useFeatureFlags(Feature.PdpGalleryV2);
  const { allowSell } = useEnabledCustomerExperiences();

  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  // eslint-disable-next-line no-nested-ternary
  const chipTitle = isStock ? (isVideo ? 'Stock Video' : 'Stock Image') : 'Seller Image';

  let backgroundColor = shopCss?.productBanner?.backgroundColor || defaultTreetStyles.gray80;
  let fontColor = shopCss?.productBanner?.fontColor;

  if (!allowSell) return null;

  if (!isPdpGalleryV2Enabled) {
    return (
      <imagechip backgroundColor="{backgroundColor}" className="{css.imageChip}" label="{" <TypographyWrapper="" variant="body1" typographyOverrides="{{" style:="" {="" color:="" fontColor="" ||="" 'white'="" }="" }}="">
            {chipTitle}
          
        }
      />
    );
  }

  backgroundColor = isStock ? defaultTreetStyles.gray00 : defaultTreetStyles.gray100;
  fontColor = isStock ? defaultTreetStyles.gray80 : fontColor || defaultTreetStyles.gray10;

  return (
    <imagechip backgroundColor="{backgroundColor}" className="{css.imageChipV2}" style="{{" borderRadius:="" '30px',="" padding:="" isStock="" ?="" undefined="" :="" '0="" 4px'="" }}="" avatar="{" (="" <AvatarSmall="" user="{ensuredUser}" disableProfileLink="" noProfileImageIcon="{" <IconUserFilled="" color="{defaultTreetStyles.gray10}"></imagechip>
            }
          />
        )
      }
      label={
        <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" fontColor="" }="" }}="">
          {chipTitle}
        </typographywrapper>
      }
    />
  );
};

const Tab = (props) => {
  const { handleClick, isActive, tabName } = props;

  const fontColor = isActive ? defaultTreetStyles.gray100 : defaultTreetStyles.gray40;

  return (
    <inlinetextbutton 0="" onClick="{handleClick}" typographyVariant="body2" typographyFormat="{isActive" ?="" TypographyFormat.Underlined="" :="" undefined}="" typographyWeight="{TypographyWeight.Bold}" style="{{" paddingTop:="" 0,="" paddingBottom:="" }}="" typographyOverrides="{{" style:="" {="" lineHeight:="" '16px',="" fontSize:="" '13px',="" color:="" fontColor,="" paddingLeft:="" '4px',="" paddingRight:="" textDecorationColor:="" },="">
      {tabName}
    </inlinetextbutton>
  );
};

const SwiperCarousel = (props) => {
  const {
    carouselMedia,
    thumbnailImages,
    numStockMedia,
    numSellerMedia,
    swiperWrapperRef,
    isOwnListing,
    listing,
    editParams,
  } = props;

  const {
    listingFlowConfig: { featuredListingImageType },
  } = useShopConfig();
  const carouselSwiperRef = useRef(null);
  const firstCarouselSlideRef = useRef(null);

  const isMobile = useIsMobile();
  const theme = useTheme();
  const isMediaQueryLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [swiperLibrary, setSwiperLibrary] = useState(undefined);
  const [swiperReactLibrary, setSwiperReactLibrary] = useState(undefined);
  const [activeTab, setActiveTab] = useState(featuredListingImageType);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      // Load this in asynchronously
      // because for some reason, it runs into an error with SSR
      const SwiperCore = await import('swiper');
      const SwiperReact = await import('swiper/react');
      const SwiperStyles = await import('./swiperStylesUtils');
      await SwiperStyles.default();
      setSwiperLibrary(SwiperCore);
      setSwiperReactLibrary(SwiperReact);
    };

    loadData();
  }, []);

  const firstTab = featuredListingImageType;
  const secondTab = Object.keys(FEATURED_LISTING_IMAGE_TYPE).find((type) => type !== firstTab);
  const secondTabFirstImageIndex =
    featuredListingImageType === FEATURED_LISTING_IMAGE_TYPE.STOCK ? numStockMedia : numSellerMedia;

  const toggleActiveTab = (tab) => {
    setActiveTab(tab);
    if (carouselSwiperRef?.current && carouselSwiperRef.current.swiper) {
      const { swiper } = carouselSwiperRef.current;
      const indexToScroll = tab === firstTab ? 0 : secondTabFirstImageIndex;
      swiper.slideTo(indexToScroll);
    }
  };

  const handleSlideChange = debounce((swiper) => {
    const imageIndex = swiper.realIndex;
    const isIndexOverSecondTabFirstImageIndex = imageIndex >= secondTabFirstImageIndex;
    setActiveSlideIndex(imageIndex);
    setActiveTab(isIndexOverSecondTabFirstImageIndex ? secondTab : firstTab);
  }, 200);

  if (!swiperLibrary || !swiperReactLibrary) return null;

  const hasSellerAndStockImages = numStockMedia > 0 && numSellerMedia > 0;

  const { Swiper, SwiperSlide } = swiperReactLibrary;
  const { Navigation, Mousewheel, Thumbs } = swiperLibrary;

  return (
    <box ref="{swiperWrapperRef}" display="flex" flexDirection="{{" xs:="" 'column-reverse',="" lg:="" 'row'="" }}="">
      <box 0="" maxHeight="100%" width="{{" xs:="" '85%',="" lg:="" MEDIA_QUERY_LARGE_CAROUSEL_THUMBNAIL_WIDTH="" }}="" margin="{{" '24px="" auto="" 0',="" '0="" 12px="" 0'="">
        {hasSellerAndStockImages && (
          <box display="flex" justifyContent="center" mb="{2}">
            <tab handleClick="{()" ==""> toggleActiveTab(firstTab)}
              isActive={activeTab === firstTab}
              tabName={FEATURED_LISTING_IMAGE_TYPE_TAB_NAMES[firstTab]}
            />
            <tab handleClick="{()" ==""> toggleActiveTab(secondTab)}
              isActive={activeTab === secondTab}
              tabName={FEATURED_LISTING_IMAGE_TYPE_TAB_NAMES[secondTab]}
            />
          </tab></tab></box>
        )}
        <swiper onSwiper="{setThumbsSwiper}" modules="{[Mousewheel]}" mousewheel="" navigation="{{" nextEl:="" '.swiper-button-next',="" prevEl:="" '.swiper-button-prev',="" }}="" className="{classNames(`${css.thumbnailsSwiper}" thumbs-wrapper`,="" {="" [css.thumbnailsSwiperWithTabs]:="" hasSellerAndStockImages,="" })}="" cssMode="" enabled="" prevents="" lag="" on="" mobile="" when="" swiping="" breakpoints="{{" 0:="" direction:="" 'horizontal',="" slidesPerView:="" 5,="" spaceBetween:="" 10,="" },="" 1024:="" 'vertical',="" 'auto',="">
          {thumbnailImages.map((image) => (
            <swiperslide key="{image.key}">{image}</swiperslide>
          ))}
        </swiper>
      </box>

      <box maxHeight="100%" width="{{" xs:="" '100%',="" lg:="" `calc(100%="" -="" ${MEDIA_QUERY_LARGE_CAROUSEL_THUMBNAIL_WIDTH})`="" }}="">
        <swiper ref="{carouselSwiperRef}" thumbs="{{" swiper:="" thumbsSwiper="" }}="" onSlideChange="{handleSlideChange}" slidesPerView="{1}" cssMode="" enabled="" prevents="" lag="" on="" mobile="" when="" swiping="" pagination="{{" clickable:="" true="" scrollbar="{{" draggable:="" allowTouchMove="{isMobile}" navigation="{{" nextEl:="" '.swiper-button-next',="" prevEl:="" '.swiper-button-prev',="" className="{`${css.carouselMediaSwiper}" carouselImagesWrapper`}="" modules="{[Navigation," Thumbs]}="">
          {carouselMedia.map((media) => {
            const isVideo = media.props.type === MEDIA_TYPE.VIDEO;

            const urlPattern =
              /https:\/\/ucarecdn\.com\/[a-f0-9]{8}-(?:[a-f0-9]{4}-){3}[a-f0-9]{12}\//;
            const strippedImageUrl = urlPattern.exec(media.key)?.[0];
            const image = (listing.attributes.publicData?.images || []).find(
              (uploadcareImage) => uploadcareImage.originalUrl === strippedImageUrl
            );
            const isBackgroundUploadcareRemoved = !!image?.isBackgroundRemoved;

            return (
              <swiperslide ref="{firstCarouselSlideRef}" key="{media.key}">
                {isMediaQueryLarge && (
                  <box display="flex" justifyContent="flex-end" position="absolute" top="{0}" width="100%" onClick="{(e)" ==""> e.stopPropagation()}
                  >
                    <box width="100%" position="relative">
                      <actionbarmaybedesktop isOwnListing="{isOwnListing}" listing="{listing}" editParams="{editParams}"></actionbarmaybedesktop>
                    </box>
                  </box>
                )}
                {isVideo ? (
                  <box display="flex" justifyContent="center" alignItems="center" style="{{" backgroundColor:="" 'black'="" }}="" height="{{" md:="" '100%'="">
                    <box maxHeight="{{" xs:="" '400px',="" md:="" '100%'="" }}="" maxWidth="100%">
                      {media}
                    </box>
                  </box>
                ) : isBackgroundUploadcareRemoved ? (
                  media
                ) : (
                  <backgroundblurbox imageSrc="{media.key}" key="{`${media.key}-${activeSlideIndex}`}">
                    {media}
                  </backgroundblurbox>
                )}
              </swiperslide>
            );
          })}
          <div className="swiper-button-next" style="{SWIPER_ARROW_STYLE}">
            <chevronrighticon></chevronrighticon>
          </div>
          <div className="swiper-button-prev" style="{SWIPER_ARROW_STYLE}">
            <chevronlefticon></chevronlefticon>
          </div>
        </swiper>
      </box>
    </box>
  );
};

const SectionImages = () => {
  const {
    maxStockImages,
    chooseStockImagesOption,
    listingFlowConfig: { featuredListingImageType },
    imageRatio,
  } = useShopConfig();

  const params = useParams();
  const swiperWrapperRef = useRef(null);
  const isMobile = useIsMobile();
  const isPdpGalleryV2Enabled = useFeatureFlags(Feature.PdpGalleryV2);
  const { currentListing, isOwnListing } = useCurrentListing();

  const listingId = new UUID(params.id);
  const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;

  const {
    imageSource,
    images: uploadcareImages,
    stockVideoUrls = [],
  } = currentListing.attributes.publicData || {};
  const isUploadcareEnabled =
    useFeatureFlags(Feature.ImagesAPI) && imageSource === ImageSource.Uploadcare;

  const theme = useTheme();
  const isMediaQueryLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const [swipeEnabled, setSwipeEnabled] = useState(true);
  const [touchEventsSet, setTouchEventsSet] = useState(false);
  const [erroredMediaSrcs, setErroredMediaSrcs] = useState([]);

  const doNotDynamicallyResize = isMobile && isPdpGalleryV2Enabled;

  const dynamicallyScaleCarouselSize = () => {
    if (doNotDynamicallyResize) return;

    // Based on the image ratio, we want to fix the height of the carousel so we don't
    // resize all the images based on the largest one
    const carouselElementIdentifier = isPdpGalleryV2Enabled
      ? CAROUSEL_ELEMENT_IDENTIFIER_PDP_GALLERY_V2
      : CAROUSEL_ELEMENT_IDENTIFIER;
    const sliderWrapper = document.querySelector(carouselElementIdentifier);
    if (!sliderWrapper) return;

    // For brands with a low image ratio, we want to make it larger to accommodate for seller
    // images that are usually taken on a phone and thus have a vertical aspect ratio. We do
    // this across every listing so that the images do not jump around while toggling listings
    const displayRatio = imageRatio <= 1 ? FALLBACK_IMAGE_RATIO_FOR_VERTICAL_IMAGES : imageRatio;
    const carouselWidth = sliderWrapper.clientWidth;
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const vhBasedHeight = vh - TOPBAR_HEIGHT_DESKTOP - 120; // extra padding for bottom
    const windowBasedHeight = Math.max(vhBasedHeight, MIN_FULL_SCREEN_CAROUSEL_HEIGHT);
    const heightToUse = `${Math.min(
      Math.round(carouselWidth * displayRatio),
      windowBasedHeight
    )}px`;

    if (swiperWrapperRef.current && isMediaQueryLarge) {
      swiperWrapperRef.current.style.height = heightToUse;
    }
    sliderWrapper.style.height = heightToUse;
  };

  useEffect(() => {
    if (doNotDynamicallyResize) return undefined;
    const classNameTargetToObserver = isPdpGalleryV2Enabled
      ? CAROUSEL_CLASSNAME_TARGET_TO_OBSERVE_PDP_GALLERY_V2
      : CAROUSEL_CLASSNAME_TARGET_TO_OBSERVE;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target.classList.contains(classNameTargetToObserver)) {
          dynamicallyScaleCarouselSize();
        }
      });
    });

    const sliderWrapper = document.querySelector(CAROUSEL_ELEMENT_IDENTIFIER);
    if (sliderWrapper) {
      resizeObserver.observe(sliderWrapper);
    }

    return () => {
      if (sliderWrapper) {
        resizeObserver.unobserve(sliderWrapper);
      }
    };
  }, [isPdpGalleryV2Enabled, doNotDynamicallyResize]);

  useEffect(() => {
    const els = document.getElementsByClassName('thumbs-wrapper');
    // This removes the touchmove listener from the "thumbs animated" element by applying this event listener to the parent in react-responsive-carousel. Without it, when a user scrolls the page on mobile and scrolls though the "thumbs animated", the page scroll does not work and the element becomes a dead zone.
    // When we apply directly to the element, the original event listener is taking precedent
    // https://stackoverflow.com/questions/19469881/remove-all-event-listeners-of-specific-type
    if (els?.[0]) {
      els[0].addEventListener(
        'touchmove',
        (event) => {
          event.stopPropagation();
        },
        true
      );
    }
  }, []);

  // Prevent vertical scrolling when horizontal swiping, and prevent horizontal swiping when vertical scrolling
  // Solution taken from: https://github.com/leandrowd/react-responsive-carousel/issues/358
  useEffect(() => {
    const disableScroll = () => {
      const carousel = document.querySelector('.carousel');

      let startPos;
      let endPos;
      let isScrolling;
      let passedSwipeScrollTolerance = false;

      function touchMove(e) {
        if (e.targetTouches.length !== 1 || (e.scale && e.scale !== 1) || !startPos) {
          return;
        }

        if (!passedSwipeScrollTolerance && swipeEnabled) {
          const touch = e.targetTouches[0];
          const swipeScrollTolerance = 30;

          endPos = {
            x: Math.abs(touch.pageX - startPos.x),
            y: Math.abs(touch.pageY - startPos.y),
          };
          passedSwipeScrollTolerance = endPos.x > swipeScrollTolerance;

          isScrolling = endPos.x < endPos.y; // When isScrolling it means vertical sliding
          if (isScrolling) {
            setSwipeEnabled(false);
          }
        }
      }

      function touchEnd() {
        setSwipeEnabled(true);
        passedSwipeScrollTolerance = false;
        carousel?.removeEventListener('touchmove', touchMove);
      }

      function touchStart(e) {
        const touch = e.targetTouches[0]; // The touches array object gets all the touches on the screen, taking the first touch
        startPos = {
          x: touch.pageX,
          y: touch.pageY,
        };

        carousel?.addEventListener('touchmove', touchMove);
      }

      if (!touchEventsSet) {
        carousel?.addEventListener('touchstart', touchStart);
        carousel?.addEventListener('touchend', touchEnd);
        setTouchEventsSet(true);
      }

      if (!swipeEnabled) {
        setSwipeEnabled(true);
        passedSwipeScrollTolerance = false;
      }
    };
    disableScroll();
  }, [swipeEnabled]);

  useEffect(() => {
    dynamicallyScaleCarouselSize();
  }, [currentListing]);

  // Needed for swiper carousel to size image correctly
  const swiperCarouselClassName = classNames({ [css.swiperCarouselImage]: isPdpGalleryV2Enabled });

  const getValidStockImages = () => {
    const stockImages =
      maxStockImages > 0 ? getStockImagesForListing(currentListing, chooseStockImagesOption) : [];
    // Make sure we only have x stock images max
    return stockImages.filter((src) => !erroredMediaSrcs.includes(src)).slice(0, maxStockImages);
  };

  const currentListingId = currentListing.id.uuid;

  const getStockImageEls = (shouldMagnify) => {
    const validStockImages = getValidStockImages();

    return validStockImages.map((src) => {
      const imageUrl = isMobile
        ? getUploadcareImagePreviewUrl(src)
        : getLargeUploadcareImagePreviewUrl(src);

      return shouldMagnify && !isMobile ? (
        <magnifyimage key="{imageUrl}" imageSrc="{imageUrl}" imageAlt="product stock photo" listingId="{currentListingId}"></magnifyimage>
      ) : (
        <img className="{swiperCarouselClassName}" src="{imageUrl}" key="{imageUrl}" alt="product stock photo" onError="{()" ==""> setErroredMediaSrcs([...erroredMediaSrcs, src])}
        />
      );
    });
  };

  const getValidStockVideos = () => stockVideoUrls.filter((src) => !erroredMediaSrcs.includes(src));

  const getStockVideoEls = (shouldAutoplayVideo) => {
    const validStockVideos = getValidStockVideos();
    return validStockVideos.map((src) => (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video src="{src}" key="{src}" autoPlay="{shouldAutoplayVideo" &&="" !isMobile}="" controls="{isMobile}" playsInline="" muted="" loop="" className="{classNames(css.sectionMediaVideo," swiperCarouselClassName)}="" width="100%" onError="{()" ==""> setErroredMediaSrcs([...erroredMediaSrcs, src])}
      />
    ));
  };

  const getSellerImageEls = (shouldMagnify) => {
    const sellerImageEls = [];
    if (isUploadcareEnabled) {
      uploadcareImages?.forEach((image) => {
        const imageUrl = isMobile
          ? getUploadcareImagePreviewUrl(image.cdnUrl)
          : getLargeUploadcareImagePreviewUrl(image.cdnUrl);
        const sellerImage =
          shouldMagnify && !isMobile ? (
            <magnifyimage key="{imageUrl}" imageSrc="{imageUrl}" imageAlt="seller uploaded" listingId="{currentListingId}"></magnifyimage>
          ) : (
            <img className="{swiperCarouselClassName}" key="{imageUrl}" src="{imageUrl}" alt="seller uploaded">
          );
        sellerImageEls.push(sellerImage);
      });
    } else {
      currentListing.images.forEach((image) => {
        const imageUrl =
          image.attributes.variants['scaled-medium']?.url ||
          image.attributes.variants.landscape?.url ||
          image.attributes.variants.default?.url;
        const sellerImage =
          shouldMagnify && !isMobile ? (
            <magnifyimage key="{imageUrl}" imageSrc="{imageUrl}" imageAlt="seller uploaded" listingId="{currentListingId}"></magnifyimage>
          ) : (
            <img src="{imageUrl}" key="{imageUrl}" alt="seller uploaded" className="{swiperCarouselClassName}">
          );
        sellerImageEls.push(sellerImage);
      });
    }
    return sellerImageEls;
  };

  const renderCarouselMedia = (carouselProps) => {
    const { shouldDisplayChip, shouldAutoplayVideo, shouldMagnify } = carouselProps || {};

    const stockImageEls = getStockImageEls(shouldMagnify);
    const stockVideoEls = getStockVideoEls(shouldAutoplayVideo);
    const sellerImageEls = getSellerImageEls(shouldMagnify);

    const areSellerImagesFeatured =
      featuredListingImageType === FEATURED_LISTING_IMAGE_TYPE.USER_UPLOADED;

    let allMediaEls = [];
    if (areSellerImagesFeatured) {
      allMediaEls = [...sellerImageEls, ...stockImageEls, ...stockVideoEls];
    } else {
      allMediaEls = [...stockImageEls, ...stockVideoEls, ...sellerImageEls];
    }

    if (!shouldDisplayChip) return allMediaEls;

    // Add a chip to each image/video to denote if it is a seller or stock image/video
    return allMediaEls.map((mediaEl, index) => {
      const numStockMedia = stockImageEls.length + stockVideoEls.length;
      const isStock = areSellerImagesFeatured
        ? index >= allMediaEls.length - numStockMedia
        : index < numStockMedia;
      const isVideo = mediaEl.type === MEDIA_TYPE.VIDEO;

      return (
        <box display="flex" justifyContent="center" height="100%" key="{mediaEl.key" ||="" index}="">
          <stockorsellerchip isStock="{isStock}" isVideo="{isVideo}" user="{currentListing.author}"></stockorsellerchip>
          {mediaEl}
        </box>
      );
    });
  };

  const listingSlug = params.slug || createSlug(currentListing.attributes.title || '');
  const listingType = isDraftVariant ? ListingPageParamType.Draft : ListingPageParamType.Edit;
  // Default to whatever page the user is on if it's a draft, otherwise go to the pricing page
  // (most commonly edited page).
  // NOTE: if the default draft tab ever changes, make sure to update abandonedListingEmailJob also.
  const listingTab = isDraftVariant ? 'shipping' : 'pricing';
  const editParams = {
    id: listingId.uuid,
    slug: listingSlug,
    type: listingType,
    tab: listingTab,
  };

  if (isPdpGalleryV2Enabled) {
    return (
      <box 3="" mt="{{" xs:="" 0,="" md:="" }}="">
        {!isMediaQueryLarge && (
          <box onClick="{(e)" ==""> e.stopPropagation()}>
            <box width="100%">
              <actionbarmaybe isOwnListing="{isOwnListing}" listing="{currentListing}" editParams="{editParams}"></actionbarmaybe>
            </box>
          </box>
        )}
        <swipercarousel key="{currentListingId}" carouselMedia="{renderCarouselMedia({" shouldDisplayChip:="" true,="" shouldAutoplayVideo:="" shouldMagnify:="" })}="" thumbnailImages="{renderCarouselMedia()}" numStockMedia="{getValidStockImages().length" +="" getValidStockVideos().length}="" numSellerMedia="{getSellerImageEls().length}" swiperWrapperRef="{swiperWrapperRef}" isOwnListing="{isOwnListing}" listing="{currentListing}" editParams="{editParams}"></swipercarousel>
      </box>
    );
  }

  // Need to custom render thumbnails because we want to set video autoplay to false
  const handleRenderThumbnails = (children) =>
    children.map((item) => {
      const media = item.props.children?.find((child) =>
        Object.values(MEDIA_TYPE).includes(child.type)
      );

      if (!media) return item;

      const { className, src, width, alt } = media.props;
      if (media.type === MEDIA_TYPE.VIDEO) {
        return (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video key="{media.key}" src="{src}" className="{className}" width="{width}" autoPlay="{false}"></video>
        );
      }
      return <img key="{media.key}" src="{src}" className="{className}" alt="{alt}">;
    });

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = currentListing.id ? (
    <box onClick="{(e)" ==""> e.stopPropagation()}>
      <box>
        <actionbarmaybe isOwnListing="{isOwnListing}" listing="{currentListing}" editParams="{editParams}"></actionbarmaybe>
      </box>
    </box>
  ) : null;

  return (
    <div className="{css.sectionImages}">
      <div className="{css.threeToTwoWrapper}">
        {actionBar}
        <carousel key="{currentListingId}" showStatus="{false}" showArrows="" swipeable="{swipeEnabled}" renderThumbs="{handleRenderThumbnails}" renderArrowPrev="{(onClickHandler," hasPrev,="" label)="">
            hasPrev && (
              <button 0="" type="button" onClick="{onClickHandler}" title="{label}" className="{css.imageCarouselArrows}" style="{{" left:="" }}="">
                <chevronlefticon></chevronlefticon>
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button 0="" type="button" onClick="{onClickHandler}" title="{label}" className="{css.imageCarouselArrows}" style="{{" right:="" }}="">
                <chevronrighticon></chevronrighticon>
              </button>
            )
          }
        >
          {renderCarouselMedia({ shouldDisplayChip: true, shouldAutoplayVideo: true })}
        </carousel>
      </div>
    </div>
  );
};

Builder.registerComponent(SectionImages, {
  name: 'SectionImages',
});

export default SectionImages;
</video></imagechip>