/* eslint-disable import/prefer-default-export */
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { IconSpeechBubble, ListingFeedbackModal, TypographyWrapper } from '../../components';
import {
  TypographyFormat,
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import { ModalType, setActiveModal } from '../../ducks/modal.duck';
import { useActiveModal } from '../../hooks/useActiveModal';
import { ListingWithAuthor } from '../../types/sharetribe/listing';
import { showListingFeedbackBuyerSatisfactionForm } from '../../util/refiner';
import { parse } from '../../util/urlHelpers';
import { LISTING_FEEDBACK_BUYER_UPDATE_EMAIL_UTM_CAMPAIGN } from '../../util/sendgrid';
import css from './ListingPage.module.css';

interface SectionListingFeedbackProps {
  className?: string;
  listing: ListingWithAuthor;
}

export const SectionListingFeedback: FC<sectionlistingfeedbackprops> = (props) => {
  const { className, listing } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const { isModalOpen: isListingFeedbackModalOpen, closeModal: closeListingFeedbackModal } =
    useActiveModal(ModalType.ListingFeedback);

  const { currentUser } = useSelector<any>((state) => state.user) as any;

  const handleListingFeedbackBannerClick = () => {
    dispatch(setActiveModal(ModalType.ListingFeedback));
  };

  const queryParams = parse(location.search);
  if (queryParams.utm_campaign === LISTING_FEEDBACK_BUYER_UPDATE_EMAIL_UTM_CAMPAIGN) {
    const email = queryParams.contact_email;
    showListingFeedbackBuyerSatisfactionForm(!currentUser && email);
  }

  return (
    <box>
      <listingfeedbackmodal isOpen="{isListingFeedbackModalOpen}" handleClose="{closeListingFeedbackModal}" handleSuccess="{closeListingFeedbackModal}" listing="{listing}"></listingfeedbackmodal>
      <box className="{classNames(className," css.sectionIso)}="" onClick="{handleListingFeedbackBannerClick}">
        <box display="flex" flexDirection="row" width="100%">
          <box display="flex" alignItems="center" pr="{3}">
            <iconspeechbubble className="{css.sectionIcon}"></iconspeechbubble>
          </box>
          <box display="flex" flexDirection="column" mr="{2}">
            <typographywrapper variant="body2" weight="{TypographyWeight.Bold}" typographyOverrides="{{" style:="" {="" paddingBottom:="" '3px'="" }="" }}="">
              Questions about this item?
            </typographywrapper>
            <box display="flex" flexDirection="row" alignItems="center">
              <box mr="{1}">
                <typographywrapper variant="body2" format="{TypographyFormat.Underlined}">
                  Send feedback to the seller
                </typographywrapper>{' '}
              </box>
            </box>
          </box>
        </box>
      </box>
    </box>
  );
};
</any></sectionlistingfeedbackprops>