import Box from '@material-ui/core/Box';
import React from 'react';
import TypographyWrapper, {
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import { propTypes } from '../../util/types';
import { sanitizeSpaces } from '../../util/strings';

const SectionBox = ({ children }) => <box mb="{2}">{children}</box>;

const SectionQuirksAndNotes = (props) => {
  const { listing } = props;

  const { publicData } = listing.attributes;
  const { conditionInfo } = publicData;
  const { quirks, notes } = conditionInfo || {};

  function renderQuirks() {
    return quirks.join(', ');
  }

  return (
    <box>
      {quirks?.length > 0 && (
        <sectionbox>
          <typographywrapper variant="body1" weight="{TypographyWeight.Bold}" typographyOverrides="{{" display:="" 'inline'="" }}="">
            Quirks:
          </typographywrapper>{' '}
          <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
            {renderQuirks()}
          </typographywrapper>
        </sectionbox>
      )}
      {notes && (
        <sectionbox>
          <typographywrapper variant="body1" weight="{TypographyWeight.Bold}" typographyOverrides="{{" display:="" 'inline'="" }}="">
            Seller Notes:
          </typographywrapper>{' '}
          <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline',="" style:="" {="" whiteSpace:="" 'pre-wrap'="" }="" }}="">
            {sanitizeSpaces(notes)}
          </typographywrapper>
        </sectionbox>
      )}
    </box>
  );
};

SectionQuirksAndNotes.propTypes = {
  listing: propTypes.listing.isRequired,
};

export default SectionQuirksAndNotes;
