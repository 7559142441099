import classNames from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage, NamedLink, TypographyWrapper } from '../../components';
import { ITEM_AVAILABILITY_PURCHASED } from '../../util/constants';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  LISTING_STATE_PENDING_APPROVAL,
} from '../../util/types';
import EditIcon from './EditIcon';
import css from './ListingPage.module.css';
import { Listing } from '../../types/sharetribe/listing';

interface ActionBarMaybeDesktopProps {
  isOwnListing: boolean;
  listing: Listing;
  editParams: any;
}

const ActionBarMaybeDesktop: FC<actionbarmaybedesktopprops> = (props) => {
  const { isOwnListing, listing, editParams } = props;

  const { state } = listing.attributes;
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const {
    publicData: { availability },
  } = listing.attributes;
  const isPurchased = availability === ITEM_AVAILABILITY_PURCHASED;

  if (!isOwnListing && !isClosed) return null;

  if (isOwnListing) {
    let ownListingTextTranslationId = 'ListingPage.ownListing';

    if (isPendingApproval) {
      ownListingTextTranslationId = 'ListingPage.ownListingPendingApproval';
    } else if (isClosed) {
      ownListingTextTranslationId = 'ListingPage.ownClosedListing';
    } else if (isDraft) {
      ownListingTextTranslationId = 'ListingPage.ownListingDraft';
    }

    const message = isDraft ? 'ListingPage.finishListing' : 'ListingPage.editListing';

    const ownListingTextClasses = classNames(css.ownListingText, {
      [css.ownListingTextPendingApproval]: isPendingApproval,
    });

    return (
      <div className="{css.actionBarDesktop}">
        <p className="{ownListingTextClasses}">
          <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" 'white'="" }="" }}="">
            <formattedmessage id="{ownListingTextTranslationId}"></formattedmessage>
          </typographywrapper>
        </p>
        {!isPurchased && (
          <namedlink className="{css.editListingLink}" name="EditListingPage" params="{editParams}">
            <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" 'white'="" }="" }}="">
              <editicon className="{css.editIcon}"></editicon>
              <formattedmessage id="{message}"></formattedmessage>
            </typographywrapper>
          </namedlink>
        )}
      </div>
    );
  }

  return (
    <div className="{css.actionBarDesktop}">
      <p className="{css.closedListingText}">
        <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" 'white'="" }="" }}="">
          <formattedmessage id="ListingPage.closedListing"></formattedmessage>
        </typographywrapper>
      </p>
    </div>
  );
};

export default ActionBarMaybeDesktop;
</actionbarmaybedesktopprops>