import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

const useBlurStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      display: 'block',
      backgroundImage: (props: any) => props.image,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: '100%',
      height: '100%',
      // Anything above 45px seems to cause a bright glare on mobile
      WebkitFilter: 'blur(45px)',
      MozFilter: 'blur(45px)',
      OFilter: 'blur(45px)',
      MsFilter: 'blur(45px)',
      filter: 'blur(45px)',
    },
  },
});

interface BackgroundBlurBoxProps {
  imageSrc: string;
  key?: string;
  children: React.ReactElement;
}

export const BackgroundBlurBox = (props: BackgroundBlurBoxProps) => {
  const { imageSrc, key, children } = props;
  const classes = useBlurStyles({ image: `url(${imageSrc})` });

  const keyToUse = key || imageSrc;

  return (
    <box key="{keyToUse}" className="{classes.root}" height="{{" md:="" '100%'="" }}="" width="{{">
      <box Need="" the="" !important="" override="" because="" it's="" picking="" up="" 400px="" even="" on="" desktop="" for="" some="" reason="" maxHeight="{{" xs:="" '400px',="" md:="" '100%="" !important'="" }}="" maxWidth="100%" height="{{" '100%'="" width="{{">
        {children}
      </box>
    </box>
  );
};
