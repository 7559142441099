/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MuiDivider from '@material-ui/core/Divider';
import { bool, oneOf, shape, string } from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import classNames from 'classnames';
import { Builder } from '@builder.io/react';
import { isEmpty } from 'lodash';
import { NotFoundPage, TopbarContainer } from '..';
import {
  BuilderSection,
  Button,
  Footer,
  FormattedMessage,
  IconArrowRight,
  InlineTextButton,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  NamedRedirect,
  Page,
} from '../../components';
import AppContext from '../../context/AppContext';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { formatMoney } from '../../util/currency';
import { ensureUser, userDisplayNameAsString } from '../../util/data';
import { injectIntl, intlShape } from '../../util/reactIntl';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_PUBLISHED,
  propTypes,
} from '../../util/types';
import {
  createSlug,
  getListingUrl,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import {
  fetchAuthorListings,
  fetchRecommendedListings,
  NUM_RECOMMENDED_LISTINGS,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import { useShopConfig } from '../../hooks/shopConfig';
import { useIsMobile } from '../../hooks/useIsMobile';
import TypographyWrapper from '../../components/TypographyWrapper/TypographyWrapper';
import ListingItemsCarousel from '../../components/ListingItemsCarousel/ListingItemsCarousel';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import { RequestStatus } from '../../types/requestStatus';
import { getUploadcarePreserveFormatUrl, ImageSource } from '../../util/uploadcare';
import { getStockImagesForListing } from '../../util/listings/listingImages';
import {
  createListingDescriptionForMarkup,
  createListingDescriptionForMeta,
  getShippingDetailsForMarkup,
  getStructuredMarkupAvailability,
  getStructuredMarkupItemCondition,
} from '../../util/seo';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { ListingItemType } from '../../types/sharetribe/listing';
import { trackClickListingCard } from '../../util/heap';
import { useAppDispatch } from '../../hooks/appDispatch';
import { BuilderSections } from '../../util/builder';
import { useCurrentListing } from './hooks/useCurrentListing';
import SectionDetails from './SectionDetails';
import css from './ListingPage.module.css';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';

const priceData = (price, intl) => {
  if (price) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  }
  return {};
};

const RecommendedListingsCarousel = () => {
  const { recommendedListings, fetchRecommendedListingsStatus } = useSelector(
    (state) => state.ListingPage
  );
  const rawParams = useParams();
  const isMobile = useIsMobile();
  const { currentListing, isOwnListing } = useCurrentListing();

  const trackListingCardHeapEvent = (params) => {
    trackClickListingCard({
      ...params,
      originOfClick: 'RecommendedListingsCarousel',
    });
  };

  const isPublished = currentListing.attributes.state === LISTING_STATE_PUBLISHED;
  const isClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;

  const shouldShowRecommendedListings =
    currentListing &&
    (isPublished || isClosed) &&
    !(isPendingApprovalVariant || isDraftVariant) &&
    !isOwnListing;
  const hasEnoughListings = recommendedListings?.length >= NUM_RECOMMENDED_LISTINGS;

  if (
    !shouldShowRecommendedListings ||
    fetchRecommendedListingsStatus === RequestStatus.Pending ||
    !hasEnoughListings
  ) {
    return null;
  }

  return (
    <>
      <muidivider></muidivider>
      <box mt="{3}" mb="{8}">
        <typography variant="h2" style="{{" textAlign:="" 'center'="" }}="">
          Other Listings You’ll Love
        </typography>
        <box 0="" mt="{3}" mb="{5}" mx="{{" xs:="" 3,="" md:="" }}="">
          <listingitemscarousel trackClickListingCardHeapEvent="{trackListingCardHeapEvent}" listings="{recommendedListings.slice(0," NUM_RECOMMENDED_LISTINGS)}="" referrerLocation="Listing Page"></listingitemscarousel>
        </box>
        <box display="flex" justifyContent="center">
          <namedlink name="SearchPage" to="{{" search:="" ''="" }}="" style="{{" textDecoration:="" 'none'="">
            {isMobile ? (
              <inlinetextbutton type="button">
                See More Items
                <iconarrowright></iconarrowright>
              </inlinetextbutton>
            ) : (
              <button className="{css.shopButton}">See More Items</button>
            )}
          </namedlink>
        </box>
      </box>
    </>
  );
};

const ListingPageContent = () => {
  const params = useParams();
  const { treetId } = useContext(AppContext);
  const rightColumnRef = useRef(null);
  const isMobile = useIsMobile();
  const { currentListing, isOwnListing } = useCurrentListing();

  const [isLeftColumnSticky, setIsLeftColumnSticky] = useState(true);

  const { currentUser } = useSelector((state) => state.user);
  const { recommendedListings } = useSelector((state) => state.ListingPage);

  const isRecommendedListingsLaunched = useFeatureFlags(
    Feature.RecommendedListings,
    treetId,
    currentUser
  );

  const shouldAllowLeftColumnToBeSticky = !isMobile;

  const handleScroll = () => {
    if (!rightColumnRef.current || !shouldAllowLeftColumnToBeSticky) return;

    const scrolled = window.scrollY;
    const stickyLimit = rightColumnRef.current.offsetHeight;

    setIsLeftColumnSticky(scrolled < stickyLimit);
  };

  useEffect(() => {
    if (!shouldAllowLeftColumnToBeSticky) return undefined;

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [shouldAllowLeftColumnToBeSticky]);

  const isPendingApprovalVariant = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;

  const isPublished = currentListing.attributes.state === LISTING_STATE_PUBLISHED;
  const isClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;

  const shouldShowRecommendedListings =
    isRecommendedListingsLaunched &&
    currentListing &&
    (isPublished || isClosed) &&
    !(isPendingApprovalVariant || isDraftVariant) &&
    !isOwnListing;

  const leftColumnStyleOverride = shouldAllowLeftColumnToBeSticky
    ? {
        alignSelf: 'start',
        position: isLeftColumnSticky ? 'sticky' : '',
        top: '100px',
      }
    : {};

  return (
    <box display="flex" flexDirection="column">
      <box display="flex">
        <grid container="" className="{css.contentContainer}">
          <grid style="{leftColumnStyleOverride}" item="" xs="{12}" md="{6}" lg="{7}" className="{css.imagesContent}">
            <sectionimages></sectionimages>
          </grid>
          <grid ref="{rightColumnRef}" item="" xs="{12}" md="{6}" lg="{5}" className="{css.detailsContent}">
            <sectiondetails></sectiondetails>
          </grid>
        </grid>
      </box>
      {shouldShowRecommendedListings && !!recommendedListings && (
        <box className="{classNames(css.carouselContainer," css.contentContainer)}="">
          <recommendedlistingscarousel></recommendedlistingscarousel>
        </box>
      )}
    </box>
  );
};

export const ListingPageComponent = (props) => {
  const { intl, params: rawParams, location, scrollingDisabled, showListingError } = props;

  const { canonicalRootUrl } = useContext(AppContext);
  const {
    treetShopName,
    shopName,
    chooseStockImagesOption,
    sizeVariantOptionName,
    showProductDescription,
    filters,
    builderConfig,
    variantOptions,
    forClientReviewOnly,
  } = useShopConfig();
  const { currencyConfig, allowedShippingDestinationCountries } = useBrandCountryConfig();
  const isImagesAPIEnabled = useFeatureFlags(Feature.ImagesAPI);
  const { isShopClosed } = useEnabledCustomerExperiences();

  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const { currentListing, isOwnListing } = useCurrentListing();

  const dispatch = useAppDispatch();
  const { fetchRecommendedListingsStatus } = useSelector((state) => state.ListingPage);

  const isPublished = currentListing.attributes.state === LISTING_STATE_PUBLISHED;
  const isClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;

  const shouldShowRecommendedListings =
    currentListing &&
    (isPublished || isClosed) &&
    !(isPendingApprovalVariant || isDraftVariant) &&
    !isOwnListing;

  useEffect(() => {
    if (shouldShowRecommendedListings && fetchRecommendedListingsStatus !== RequestStatus.Pending)
      dispatch(fetchRecommendedListings(currentListing));
  }, [currentListing?.attributes?.state, currentListing?.id]);

  useEffect(() => {
    if (currentListing?.author) dispatch(fetchAuthorListings(currentListing.author.id.uuid));
  }, [currentListing?.author?.id.uuid]);

  const authorAvailable = currentListing && currentListing.author;

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires authentication and
  // attempts to fetch the listing from own listings. This will fail with 403
  // Forbidden if the author is another user. We use this information to try
  // to fetch the public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <namedredirect name="ListingPage" params="{params}" search="{location.search}"></namedredirect>;
  }

  const { price = null, title = '', publicData } = currentListing.attributes;

  const topbar = <topbarcontainer></topbarcontainer>;

  // Trade-in listings do not have a valid ListingPage view
  const isTradeIn = publicData?.listingItemType === ListingItemType.TradeIn;
  const hasListingError = showListingError && showListingError.status === 404;
  if (hasListingError || isTradeIn) {
    // 404 listing not found
    return <notfoundpage></notfoundpage>;
  }

  if (showListingError) {
    // Other error in fetching listing

    const errorTitle = intl.formatMessage({
      id: 'ListingPage.errorLoadingListingTitle',
    });

    return (
      <page title="{errorTitle}" scrollingDisabled="{scrollingDisabled}">
        <layoutsinglecolumn className="{css.pageRoot}">
          <layoutwrappertopbar>{topbar}</layoutwrappertopbar>
          <layoutwrappermain>
            <p className="{css.errorText}">
              <formattedmessage id="ListingPage.errorLoadingListingMessage"></formattedmessage>
            </p>
          </layoutwrappermain>
          <layoutwrapperfooter>
            <footer></footer>
          </layoutwrapperfooter>
        </layoutsinglecolumn>
      </page>
    );
  }
  if (!currentListing.id) {
    // Still loading the listing

    const loadingTitle = intl.formatMessage({
      id: 'ListingPage.loadingListingTitle',
    });

    return (
      <page title="{loadingTitle}" scrollingDisabled="{scrollingDisabled}">
        <layoutsinglecolumn className="{css.pageRoot}">
          <layoutwrappertopbar>{topbar}</layoutwrappertopbar>
          <layoutwrappermain>
            <div className="{css.loadingText}">
              <typographywrapper variant="body1">
                <formattedmessage id="ListingPage.loadingListingMessage"></formattedmessage>
              </typographywrapper>
            </div>
          </layoutwrappermain>
          <layoutwrapperfooter>
            <footer></footer>
          </layoutwrapperfooter>
        </layoutsinglecolumn>
      </page>
    );
  }

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted. Because listing
  // can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const currency = price?.currency || currencyConfig.currency;
  const { formattedPrice } = priceData(price, intl);

  const getListingImages = (currListing, variantName) => {
    const { imageSource, images: uploadcareImages } = currListing.attributes.publicData || {};
    const isUploadcareEnabled = isImagesAPIEnabled && imageSource === ImageSource.Uploadcare;

    if (isUploadcareEnabled) {
      return uploadcareImages?.map((image) => ({
        url: getUploadcarePreserveFormatUrl(image?.cdnUrl),
        width: image?.originalImageInfo?.width,
        height: image?.originalImageInfo?.height,
      }));
    }

    return (currListing.images || [])
      .map((image) => {
        const { variants } = image.attributes;
        const variant = variants ? variants[variantName] : null;

        // deprecated
        // for backwards combatility only
        const { sizes } = image.attributes;
        const size = sizes ? sizes.find((i) => i.name === variantName) : null;

        return variant || size;
      })
      .filter((variant) => variant != null);
  };

  const facebookImages = getListingImages(currentListing, 'facebook');
  const twitterImages = getListingImages(currentListing, 'twitter');
  const defaultImages = getListingImages(currentListing, 'default');
  const schemaImages = [
    ...defaultImages.map((img) => img.url),
    ...getStockImagesForListing(currentListing, chooseStockImagesOption).map((imgUrl) =>
      getUploadcarePreserveFormatUrl(imgUrl)
    ),
  ];
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, siteTitle: treetShopName }
  );

  const schemaDescription = createListingDescriptionForMarkup(
    currentListing,
    showProductDescription,
    filters,
    ensuredAuthor?.attributes?.profile?.publicData?.hideIsBrandUser
  );

  const metaDescription = createListingDescriptionForMeta(
    shopName,
    treetShopName,
    currentListing,
    filters,
    ensuredAuthor,
    sizeVariantOptionName,
    variantOptions
  );

  const {
    color = '',
    deleted = false,
    shipFromCountry = 'US',
    isBrandDirect = false,
    shopifyProductVariant = {},
  } = publicData;
  const { barcode } = shopifyProductVariant;

  const listingUrl = getListingUrl(canonicalRootUrl, currentListing);

  const isListingCrawlable = !(forClientReviewOnly || isShopClosed || deleted);

  const productSchema = isListingCrawlable
    ? {
        '@context': 'http://schema.org',
        '@type': 'Product',
        sku: currentListing.id.uuid.toString(), // Google Shopping ingests this as a unique ID
        ...(isEmpty(barcode) ? {} : { gtin: barcode }),
        description: schemaDescription,
        name: title,
        image: schemaImages,
        size: currentListing.attributes.publicData?.[sizeVariantOptionName] || '',
        color,
        url: listingUrl,
        offers: {
          '@type': 'Offer',
          priceCurrency: currency,
          price: ((price?.amount || 0) / 100).toFixed(2),
          availability: getStructuredMarkupAvailability(currentListing),
          itemCondition: getStructuredMarkupItemCondition(currentListing),
          url: listingUrl,
          shippingDetails: getShippingDetailsForMarkup(
            shipFromCountry,
            allowedShippingDestinationCountries,
            isBrandDirect,
            currency
          ),
        },
        brand: {
          '@type': 'Brand',
          name: shopName,
        },
      }
    : undefined;

  const listingPageBuilderSectionId = builderConfig?.sections?.[BuilderSections.ListingPageContent];

  return (
    <page title="{schemaTitle}" scrollingDisabled="{scrollingDisabled}" author="{authorDisplayName}" contentType="website" description="{metaDescription}" facebookImages="{facebookImages}" twitterImages="{twitterImages}" pageCanonicalUrl="{listingUrl}" schema="{productSchema}" shouldNotIndex="{!isListingCrawlable}">
      <layoutsinglecolumn className="{css.pageRoot}">
        <layoutwrappertopbar>{topbar}</layoutwrappertopbar>
        <layoutwrappermain>
          {!!listingPageBuilderSectionId && (
            <buildersection sectionType="{BuilderSections.ListingPageContent}" sectionId="{listingPageBuilderSectionId}"></buildersection>
          )}
          {!listingPageBuilderSectionId && <listingpagecontent></listingpagecontent>}
        </layoutwrappermain>
      </layoutsinglecolumn>
    </page>
  );
};

ListingPageComponent.defaultProps = {
  showListingError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  scrollingDisabled: bool.isRequired,
  showListingError: propTypes.error,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;

  return {
    isAuthenticated,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

Builder.registerComponent(RecommendedListingsCarousel, {
  name: 'RecommendedListingsCarousel',
});

export default ListingPage;
