import React from 'react';
import { Box } from '@material-ui/core';
import { element, string } from 'prop-types';
import TypographyWrapper, {
  TypographyFormat,
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import { defaultTreetStyles } from '../../shopConfig/config';
import { useShopConfig } from '../../hooks/shopConfig';
import SectionQuirksAndNotes from './SectionQuirksAndNotes';
import { IconRuler } from '../../components';
import { useCurrentListing } from './hooks/useCurrentListing';
import { useVariantDetails } from './hooks/useVariantDetails';
import css from './ListingPage.module.css';

const SectionHeading = (props) => {
  const { priceTitle, formattedPrice, formattedOriginalPrice, richTitle } = props;

  const variantDetails = useVariantDetails();

  const { currentListing: listing } = useCurrentListing();
  const { sizeVariantOptionName, sizeGuideUrl, sizeGuideLabel } = useShopConfig();

  const renderVariantOptions = () =>
    Object.entries(variantDetails).map(([variantOptionName, variantOptionValue]) => (
      <box key="{`${variantOptionName}-${variantOptionValue}`}" mb="{2}" display="flex" justifyContent="space-between">
        <box>
          <typographywrapper applyNested="" variant="body1" weight="{TypographyWeight.Bold}" typographyOverrides="{{" display:="" 'inline'="" }}="">
            {variantOptionName}:
          </typographywrapper>{' '}
          <typographywrapper applyNested="" variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
            {variantOptionValue}
          </typographywrapper>
        </box>
        {variantOptionName.toLowerCase() === sizeVariantOptionName && (
          <box display="flex" alignItems="center">
            {sizeGuideUrl && (
              <a target="_blank" rel="noopener noreferrer" href="{sizeGuideUrl}" className="{css.sizeGuideLink}">
                <iconruler className="{css.sizeGuideRulerIcon}"></iconruler>
                <typographywrapper variant="body1" format="{TypographyFormat.Underlined}" typographyOverrides="{{" display:="" 'inline',="" }}="">
                  {sizeGuideLabel}
                </typographywrapper>
              </a>
            )}
          </box>
        )}
      </box>
    ));

  return (
    <div className="{css.sectionHeading}">
      <div className="{css.infoPanel}">
        <h1 className="{css.title}">{richTitle}</h1>
        <box mb="{1}" className="{css.desktopPriceContainer}">
          {formattedOriginalPrice && (
            <div className="{css.desktopOriginalPriceValue}" title="{`Original" MSRP:="" ${formattedOriginalPrice}`}="">
              <typographywrapper variant="h2" typographyOverrides="{{" style:="" {="" textDecoration:="" 'line-through'="" }="" }}="">
                {formattedOriginalPrice}
              </typographywrapper>
            </div>
          )}
          <div className="{css.desktopPriceValue}" title="{`Listed" Price:="" ${priceTitle}`}="">
            <typographywrapper variant="h2" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.red60="" }="" }}="">
              {formattedPrice}
            </typographywrapper>
          </div>
        </box>
        {renderVariantOptions()}
        <sectionquirksandnotes listing="{listing}"></sectionquirksandnotes>
      </div>
    </div>
  );
};

SectionHeading.propTypes = {
  priceTitle: string,
  formattedPrice: string,
  formattedOriginalPrice: string,
  richTitle: element,
};

export default SectionHeading;
