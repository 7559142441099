import classNames from 'classnames';
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import MuiDivider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import DOMPurify from 'dompurify';
import { bool } from 'prop-types';
import { isEmpty } from 'lodash';
import { IconAdd, NamedLink, StyledAccordion, UserCard } from '../../components';
import TypographyWrapper, {
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import { useShopConfig } from '../../hooks/shopConfig';
import { useIsMobile } from '../../hooks/useIsMobile';
import { propTypes } from '../../util/types';
import IconBuyerProtection from '../../components/Icons/IconBuyerProtection/IconBuyerProtection';
import IconHemsterRevive from '../../components/Icons/IconHemsterRevive/IconHemsterRevive';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useReturnInsurance } from '../../hooks/useReturnInsurance';
import { ensureUser } from '../../util/data';
import { useTradeInConfig } from '../../hooks/useTradeInConfig';
import { SectionListingISO } from './SectionListingISO';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import { SectionListingFeedback } from './SectionListingFeedback';
import { ITEM_AVAILABILITY_PURCHASED } from '../../util/constants';
import IconBrandDirectExplainer from '../../components/Icons/IconBrandDirectExplainer/IconBrandDirectExplainer';
import { useAllowedFindItemMethods } from '../../hooks/useAllowedFindItemMethods';
import { FindItemMethod } from '../../util/listings/listing';
import css from './ListingPage.module.css';
import { BrandDirectIconType } from '../../types/shopConfig/shopConfigV2';
import { useShopCss } from '../../hooks/useShopCss';
import { SectionListingLoyaltyPoints } from './SectionListingLoyaltyPoints';

const useAccordionStyles = makeStyles({
  root: {
    boxShadow: 'none',
    border: '0px',
    backgroundColor: (props) => props.styles?.matterColorBright,
    '& .MuiAccordionSummary-root': {
      padding: '0px',
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px',
    },
    '&:before': {
      backgroundColor: 'unset',
      height: '0',
      top: '0',
    },
  },
});

const BorderlessAccordion = (props) => {
  const { styles } = useShopConfig();
  const classes = useAccordionStyles({ styles });
  return <styledaccordion classes="{{" root:="" classes.root="" }}="" {...props}=""></styledaccordion>;
};

const SizeGuideDescription = () => {
  const { sizeGuideDescription, sizeGuideLabel } = useShopConfig();

  return (
    <borderlessaccordion name="Size Guide Description">
      <accordionsummary IconButtonProps="{{" disableRipple:="" true,="" disableFocusRipple:="" }}="" expandIcon="{<IconAdd"></accordionsummary>}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <h4 className="{css.infoLine}">
          <typographywrapper variant="body1" weight="{TypographyWeight.Bold}">
            {sizeGuideLabel}
          </typographywrapper>
        </h4>
      
      <accordiondetails>
        <h4 className="{classNames(css.infoLine," css.infoDescription)}="">
          <typographywrapper variant="body1" applyNested="">
            {sizeGuideDescription}
          </typographywrapper>
        </h4>
      </accordiondetails>
    </borderlessaccordion>
  );
};

const getProductDescription = (listing, showProductDescription, treetId) => {
  const { shopifyProduct, bigCommerceProduct } = listing.attributes.publicData;

  if (!showProductDescription) {
    return null;
  }

  if (shopifyProduct) {
    const { description, descriptionHtml } = shopifyProduct || {};

    if (treetId === 'anian') {
      return descriptionHtml.split('<!--split-->')[0];
    }

    return descriptionHtml || description || '';
  }

  if (bigCommerceProduct) {
    const { description } = bigCommerceProduct || {};
    return description || '';
  }

  return null;
};

const SectionDescriptionBox = (props) => {
  const { reducedPadding, noTopDivider, children } = props;
  return (
    <box>
      {!noTopDivider && (
        <box 0="" mt="{1}" mb="{reducedPadding" ?="" :="" 1}="">
          <muidivider></muidivider>
        </box>
      )}
      <box 1="" my="{reducedPadding" ?="" :="" 2}="">{children}</box>
    </box>
  );
};

const getSanitizedDescription = (productDescription) => {
  if (typeof window === 'undefined') {
    return productDescription;
  }
  return DOMPurify.sanitize(productDescription, {
    // Disallow style and script tags
    FORBID_TAGS: ['style', 'script'],
  });
};

const SectionDescription = (props) => {
  const { listing, currentUser, isOwnListing } = props;

  const {
    copy: { brandDirectLabel, brandDirectDescription },
    shopName,
    sizeGuideDescription,
    showProductDescription,
    shopId: treetId,
    brandDirectIconType,
    loyaltyPointsConfig,
  } = useShopConfig();
  const { brandDirectIcon } = useShopCss();
  const { showBrandDirectIconForRelistedItems } = useTradeInConfig();
  const { allowMarketplace, allowSell } = useEnabledCustomerExperiences();
  const { shouldAllowSearch: hasProductCatalog } = useAllowedFindItemMethods();

  const shouldShowLoyaltyPointsExplainer = !isEmpty(loyaltyPointsConfig);

  const isMobile = useIsMobile();

  const isBuyerSellerFeedbackEnabled = useFeatureFlags(Feature.BuyerSellerFeedback);

  const theme = useTheme();
  const { shouldOfferReturnInsurance } = useReturnInsurance();
  const { isBrand } = useCurrentUserPermissions();
  const { isBrandDirect, internalBrandNotes, isHemster, sourceListingId, findItemMethod } =
    listing.attributes.publicData;
  const isPurchased = listing.attributes.publicData?.availability === ITEM_AVAILABILITY_PURCHASED;

  const shouldShowListingFeedback =
    isBuyerSellerFeedbackEnabled &&
    allowMarketplace &&
    !isOwnListing &&
    !isPurchased &&
    !isBrandDirect;

  const ensuredAuthor = ensureUser(listing?.author);
  const shouldShowAccordionSizeDescription = !!sizeGuideDescription;
  const productDescription = getProductDescription(listing, showProductDescription, treetId);

  // Needed because we can't apply !important in inline styles
  let overrideRef;

  const body1Font = theme?.typography?.body1;

  // This is the only way to set the !important flag in React
  // TODO (sonia-y | TREET-1281): Remove this piece of code once we no longer use the fonts configured from config.js
  if (body1Font?.fontFamily) {
    overrideRef = (element) => {
      const sanitizedFontFamily = body1Font.fontFamily.replace('!important', '');
      if (element?.style)
        element.style.setProperty('font-family', sanitizedFontFamily, 'important');
    };
  }

  // RelistAsDuplicate is only available to brands
  const showIconIfRelist =
    findItemMethod === FindItemMethod.RelistAsDuplicate ||
    !sourceListingId ||
    showBrandDirectIconForRelistedItems;

  const showBDIcon =
    isBrandDirect &&
    !ensuredAuthor?.attributes?.profile?.publicData?.hideIsBrandUser &&
    (brandDirectIconType !== BrandDirectIconType.None || brandDirectIcon) &&
    showIconIfRelist;

  return (
    <box mb="{2}">
      {/* Add padding in the case that there is no product description or consolidated listings */}
      {!!productDescription && (
        <sectiondescriptionbox reducedPadding="" noTopDivider="{!isMobile}">
          <borderlessaccordion name="Product Details">
            <accordionsummary IconButtonProps="{{" disableRipple:="" true,="" disableFocusRipple:="" }}="" expandIcon="{<IconAdd"></accordionsummary>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h4 className="{css.infoLine}">
                <typographywrapper variant="body1" weight="{TypographyWeight.Bold}">
                  Product Details
                </typographywrapper>
              </h4>
            
            <accordiondetails>
              <h4 style="{theme?.typography?.body1}" ref="{overrideRef}" dangerouslySetInnerHTML="{{" __html:="" getSanitizedDescription(productDescription),="" }}="" className="{classNames(css.infoLine," css.infoDescription)}=""></h4>
            </accordiondetails>
          </borderlessaccordion>
        </sectiondescriptionbox>
      )}
      {shouldShowLoyaltyPointsExplainer && (
        <sectiondescriptionbox>
          <sectionlistingloyaltypoints loyaltyPointsConfig="{loyaltyPointsConfig}" listing="{listing}"></sectionlistingloyaltypoints>
        </sectiondescriptionbox>
      )}
      {hasProductCatalog && (
        <sectiondescriptionbox>
          <sectionlistingiso title="Not the perfect item?" currentListing="{listing}"></sectionlistingiso>
        </sectiondescriptionbox>
      )}
      {shouldShowListingFeedback && (
        <sectiondescriptionbox>
          <sectionlistingfeedback listing="{listing}"></sectionlistingfeedback>
        </sectiondescriptionbox>
      )}
      {listing.author && allowSell && (
        <sectiondescriptionbox>
          <usercard user="{listing.author}" currentUser="{currentUser}" subdomain="{treetId}"></usercard>
        </sectiondescriptionbox>
      )}
      {showBDIcon && (
        <sectiondescriptionbox>
          <box display="flex" flexDirection="row" alignItems="center">
            <iconbranddirectexplainer className="{css.brandDirectExplainerIcon}"></iconbranddirectexplainer>
            <typographywrapper variant="body2">
              {brandDirectDescription || (
                <>
                  <typographywrapper variant="body2" component="span" weight="{TypographyWeight.Bold}" typographyOverrides="{{" display:="" 'inline'="" }}="">
                    {brandDirectLabel}
                  </typographywrapper>{' '}
                  items are sold directly from {shopName}, and might be samples, production units,
                  returned items, or more.
                </>
              )}
            </typographywrapper>
          </box>
        </sectiondescriptionbox>
      )}
      {isHemster && (
        <sectiondescriptionbox>
          <box display="flex" flexDirection="row" alignItems="center">
            <iconhemsterrevive className="{css.brandDirectExplainerIcon}"></iconhemsterrevive>
            <typographywrapper variant="body2">
              <typographywrapper variant="body2" weight="{TypographyWeight.Bold}" typographyOverrides="{{" display:="" 'inline',="" }}="">
                Hemster Revive
              </typographywrapper>{' '}
              garments are brand new items that have been expertly repaired by Hemster. Garments may
              have visible repair work, making each garment unique and celebrating its journey.
            </typographywrapper>
          </box>
        </sectiondescriptionbox>
      )}
      {isBrand && internalBrandNotes && (
        <sectiondescriptionbox>
          <typographywrapper variant="body2">
            Internal Notes (not seen by users): {internalBrandNotes}
          </typographywrapper>
        </sectiondescriptionbox>
      )}
      {shouldShowAccordionSizeDescription && (
        <sectiondescriptionbox>
          <sizeguidedescription></sizeguidedescription>
        </sectiondescriptionbox>
      )}
      <sectiondescriptionbox>
        <box>
          <namedlink name="TreetProtectionPage" target="_blank">
            <box display="flex" flexDirection="row" alignItems="center" height="40px">
              <box display="flex" alignItems="center" pr="{3}">
                <iconbuyerprotection className="{css.sectionIcon}"></iconbuyerprotection>
              </box>
              <typographywrapper variant="body1" applyNested="">
                Backed By Treet Buyer Protection
              </typographywrapper>
            </box>
          </namedlink>
          {shouldOfferReturnInsurance && (
            <box height="40px">
              <typographywrapper variant="body1" applyNested="">
                <span id="seel-ra-marketing-banner-root"></span>
              </typographywrapper>
            </box>
          )}
        </box>
      </sectiondescriptionbox>
    </box>
  );
};

SectionDescription.propTypes = {
  listing: propTypes.listing.isRequired,
  currentUser: propTypes.currentUser,
  isOwnListing: bool,
};

export default SectionDescription;
