/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Box } from '@material-ui/core';
import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import TypographyWrapper, {
  TypographyWeight,
} from '../../../components/TypographyWrapper/TypographyWrapper';
import {
  IconArrowRight,
  IconCheckCircle,
  IconCheckCircleFilled,
  IconSearch,
  NamedLink,
} from '../../../components';
import { Listing } from '../../../types/sharetribe/listing';
import { findOptionsForSelectFilter } from '../../../util/search';
import { useShopConfig, useShopConfigV2 } from '../../../hooks/shopConfig';
import { formatMoney } from '../../../util/currency';
import { getShortLabel } from '../../../util/helpers';
import { formatVariantOptionForDisplay } from '../../../util/shopifyHelpers';
import { createSlug } from '../../../util/urlHelpers';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../hooks/useRouteConfiguration';
import { FrenzyApiModes } from '../../../types/frenzy/query';
import { FilterQueryParam } from '../../../types/search/search';
import { truncated } from '../../../util/strings';
import {
  getFormattedListingVariantOptionsData,
  useListingVariantOptionsForDisplay,
} from '../../../hooks/useListingVariantOptions';
import { trackSelectConsolidatedListing } from '../../../util/heap';
import AppContext from '../../../context/AppContext';
import { defaultTreetStyles } from '../../../shopConfig/config';
import { ITEM_AVAILABILITY_PURCHASED } from '../../../util/constants';
import { NO_SCROLL } from '../../../Routes';
import { MAX_NUM_CONSOLIDATED_LISTINGS_TO_SHOW } from '../ListingPage.utils';
import SectionConsolidatedListingsLinkRow from './SectionConsolidatedListingsLinkRow';
import css from './SectionConsolidatedListings.module.css';

const MAX_VARIANT_TEXT_LENGTH = 12;

interface SectionConsolidatedListingsProps {
  currentListing: Listing;
}

interface ConsolidatedListingsTableCellProps {
  cellData: string | ReactElement;
  isSelected: boolean;
  className?: string;
}

interface ConsolidatedListingsTableHeaderProps {
  title?: string;
  className?: string;
}

interface ConsolidatedListingsTableRadioButtonProps {
  isSelected: boolean;
}

const ConsolidatedListingsTableCell: FC<consolidatedlistingstablecellprops> = (props) => {
  const { cellData, isSelected, className } = props;

  return (
    <td className="{classNames(css.consolidatedTableCell," className)}="">
      <typographywrapper variant="body2" {...(isSelected="" ?="" {="" weight:="" TypographyWeight.Bold="" }="" :="" {})}="">
        {cellData}
      </typographywrapper>
    </td>
  );
};

const ConsolidatedListingsTableHeader: FC<consolidatedlistingstableheaderprops> = (props) => {
  const { title, className } = props;
  return (
    <td className="{classNames(css.consolidatedTableHeader," className)}="">
      <typographywrapper variant="body2" weight="{TypographyWeight.Bold}">
        {title}
      </typographywrapper>
    </td>
  );
};

const ConsolidatedListingsTableRadioButton: FC<consolidatedlistingstableradiobuttonprops> = (
  props
) => {
  const { isSelected } = props;
  return (
    <td className="{css.consolidatedTableRadioButtonCell}">
      {isSelected ? (
        <iconcheckcirclefilled className="{css.checkmarkChecked}"></iconcheckcirclefilled>
      ) : (
        <iconcheckcircle className="{css.checkmarkUnchecked}"></iconcheckcircle>
      )}
    </td>
  );
};

const ConsolidatedListingsTableCaretButton = () => (
  <td className="{css.consolidatedTableCell}">
    <iconarrowright className="{css.caretIcon}" color="{defaultTreetStyles.gray60}"></iconarrowright>
  </td>
);

const SectionConsolidatedListings: FC<sectionconsolidatedlistingsprops> = (props) => {
  const { currentListing } = props;

  const [selectedRowId, setSelectedRowId] = useState(currentListing.id.uuid);
  const { customVariantDropdownOptions = [], sizeVariantOptionName, filters } = useShopConfig();
  const { treetId } = useContext(AppContext);
  const { shopName } = useShopConfigV2();

  const intl = useIntl();
  const routes = useRouteConfiguration();
  const history = useHistory();
  const variantOptions = useListingVariantOptionsForDisplay();

  const variantOptionsToShow = variantOptions.filter(
    (variantOption) => !!currentListing.attributes.publicData[variantOption]
  );
  const isPurchased =
    currentListing.attributes.publicData?.availability === ITEM_AVAILABILITY_PURCHASED;

  // won't ever be null because we don't show the component otherwise
  const consolidatedListings = useSelector(
    (state: any) => state.ListingPage.consolidatedListings
  ) as Listing[];
  const listingsToShow = consolidatedListings.slice(0, MAX_NUM_CONSOLIDATED_LISTINGS_TO_SHOW);

  // If people click on recommended listings, it doesn't re-render this component
  // so we have to ensure that the initial state of this is set
  useEffect(() => {
    const currentListingId = currentListing?.id.uuid;
    if (currentListingId) {
      setSelectedRowId(currentListingId);
    }
  }, [currentListing?.id?.uuid]);

  const formattedVariantOptions = variantOptionsToShow.map((variantOption: string) =>
    formatVariantOptionForDisplay(variantOption)
  );

  const conditionOptions = findOptionsForSelectFilter('condition', filters);

  const searchParams = queryString.stringify({
    mode: FrenzyApiModes.RawQuery,
    [FilterQueryParam.keywords]: currentListing.attributes.title,
  });

  return (
    <box my="{2}">
      <box my="{2}">
        <typographywrapper variant="body1" weight="{TypographyWeight.Bold}">
          Similar Listings:
        </typographywrapper>
      </box>
      <box display="inline-block" boxShadow="0 4px 10px rgba(0, 0, 0, 0.1)" width="100%">
        <table className="{css.consolidatedTable}">
          <thead>
            <tr>
              <consolidatedlistingstableheader></consolidatedlistingstableheader>
              <consolidatedlistingstableheader title="Condition"></consolidatedlistingstableheader>
              {formattedVariantOptions.map((header) => (
                <consolidatedlistingstableheader key="{`${header}`}" title="{header}"></consolidatedlistingstableheader>
              ))}
              <consolidatedlistingstableheader title="Price"></consolidatedlistingstableheader>
              {/* Adds a header at the end for the caret */}
              {isPurchased && <consolidatedlistingstableheader></consolidatedlistingstableheader>}
            </tr>
          </thead>
          <tbody>
            {listingsToShow.map((listing: Listing, index: number) => {
              const isSelected = listing.id.uuid === selectedRowId;
              const listingId = listing.id.uuid;
              const listingSlug = createSlug(listing.attributes.title || '');
              const priceConfig = listing.attributes.price;
              const formattedVariantOptionsData = getFormattedListingVariantOptionsData(
                listing,
                customVariantDropdownOptions,
                variantOptionsToShow,
                sizeVariantOptionName,
                filters
              );
              return (
                <tr key="{listing.id.uuid}" className="{classNames(css.consolidatedTableRow," {="" [css.consolidatedTableRowSelected]:="" isSelected,="" })}="" onClick="{()" ==""> {
                    setSelectedRowId(listingId);
                    trackSelectConsolidatedListing(
                      currentListing.id.uuid,
                      listing.id.uuid,
                      index,
                      treetId,
                      shopName
                    );
                    // We add the NO_SCROLL param to disable scroll to top when navigating the table
                    const to = createResourceLocatorString(
                      'ListingPage',
                      routes,
                      {
                        slug: listingSlug,
                        id: listingId,
                      },
                      { [NO_SCROLL]: true }
                    );
                    history.replace(to);
                  }}
                  tabIndex={0}
                >
                  {!isPurchased ? (
                    <consolidatedlistingstableradiobutton isSelected="{isSelected}"></consolidatedlistingstableradiobutton>
                  ) : (
                    // Add an empty cell so the padding aligns correctly
                    <consolidatedlistingstablecell cellData="" isSelected="{false}" className="{css.consolidatedTableRadioButtonCell}"></consolidatedlistingstablecell>
                  )}

                  <consolidatedlistingstablecell cellData="{`${getShortLabel(" conditionOptions,="" listing.attributes.publicData.condition="" )}`}="" isSelected="{isSelected}"></consolidatedlistingstablecell>
                  {Object.values(formattedVariantOptionsData).map((variantValue: string) => (
                    <consolidatedlistingstablecell key="{`${listing.id.uuid}-${variantValue}`}" cellData="{truncated(variantValue," MAX_VARIANT_TEXT_LENGTH)}="" isSelected="{isSelected}" className="{css.consolidatedTableVariantCell}"></consolidatedlistingstablecell>
                  ))}
                  <consolidatedlistingstablecell cellData="{formatMoney(intl," priceConfig)}="" isSelected="{isSelected}"></consolidatedlistingstablecell>
                  {isPurchased && <consolidatedlistingstablecaretbutton></consolidatedlistingstablecaretbutton>}
                </tr>
              );
            })}
          </tbody>
        </table>
        {consolidatedListings.length > MAX_NUM_CONSOLIDATED_LISTINGS_TO_SHOW && (
          <namedlink name="LandingPage" to="{{" search:="" searchParams="" }}="" className="{css.searchAllLink}">
            <sectionconsolidatedlistingslinkrow icon="{<IconSearch" color="{defaultTreetStyles.gray40}" className="{css.linkRowIcon}"></sectionconsolidatedlistingslinkrow>}
              description={`Search all ${consolidatedListings.length} matches for this item`}
            />
          </namedlink>
        )}
      </box>
    </box>
  );
};

export default SectionConsolidatedListings;
</sectionconsolidatedlistingsprops></consolidatedlistingstableradiobuttonprops></consolidatedlistingstableheaderprops></consolidatedlistingstablecellprops>